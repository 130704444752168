import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import * as styles from "./about.module.scss"
import Layout from "../components/layout"
import { DonateButton } from "../components/donate"

const TeamMember = ({ member }) => {
  return (
    <div className={styles.teamMember}>
      <a href={member.link} target="_blank">
        <img className={styles.memberImg} src={member.img} alt={member.name} />
      </a>
    </div>
  )
}

export default function About() {
  const team = [
    {
      name: "Linhao Zhang",
      img: "/team/lzhang.jpeg",
      link: "https://www.linkedin.com/in/linhaozhang/",
    },
    {
      name: "Frank Wu",
      img: "/team/fwu.jpeg",
      link: "https://www.linkedin.com/in/frankjwu1/",
    },
    {
      name: "Ashley Lee",
      img: "/team/alee.jpeg",
      link: "https://www.linkedin.com/in/ashley-lee-00388050/",
    },
    {
      name: "Ryan Chen",
      img: "/team/rchen.jpeg",
      link: "https://www.linkedin.com/in/ryankchen/",
    },
    {
      name: "Jason Jea",
      img: "/team/jjea.jpeg",
      link: "https://www.linkedin.com/in/jason-jea/",
    },
    {
      name: "Katelyn Monaco",
      img: "/team/kmonaco.jpeg",
      link: "https://www.linkedin.com/in/katelynmonaco/",
    },
    {
      name: "Austin Wu",
      img: "/team/awu.jpeg",
      link: "https://www.linkedin.com/in/austintwu/",
    },
  ]
  return (
    <Layout title="About | Foundation 649">
      <Header />
      <div className={styles.aboutHero}>
        <h2>About</h2>
        <p>
          Foundation 649 is a 501c3 that discovers and empowers young, talented
          Asian Americans and Pacific Islanders who are actively shaping our cultural and social
          identity.
        </p>
        <DonateButton/>
      </div>
      <div className={styles.about}>
        <div className={styles.aboutSection}>
          <div className={styles.aboutSectionTitle}>Our Vision</div>
          <div className={styles.aboutSectionContent}>
            The political and social landscape of America is rapidly evolving.
            Asian Americans and Pacific Islanders play an important part in shaping what the future of
            America looks like. By elevating and empowering young AAPI
            leaders, Foundation 649 aims to cement our place and our voice in
            our country.
          </div>
        </div>
        <div className={styles.aboutSection}>
          <div className={styles.aboutSectionTitle}>Our Name</div>
          <div className={styles.aboutSectionContent}>
            <strong>649</strong> is the case number of the 1898 Supreme Court
            case{" "}
            <a
              target="_blank"
              href="https://en.wikipedia.org/wiki/United_States_v._Wong_Kim_Ark"
            >
              "United States v. Wong Kim Ark, 169 US 649,"
            </a>{" "}
            which determined that the 14th Amendment to the US Constitution
            granted birthright citizenship to all persons born in the United
            States, regardless of race or ethnicity, a cornerstone of the
            establishment of AAPIs in America.
          </div>
        </div>
        <div className={styles.aboutSection}>
          <div className={styles.aboutSectionTitle}>Our Team</div>
          <div className={styles.aboutSectionContent}>
            We are an all volunteer team scattered across the U.S., with folks
            in California, Washington, New York, Georgia, and Texas. We come from a variety
            of backgrounds, ranging from media to technology to entertainment.
            We are unified by our interest in AAPI issues and
            elevating the AAPI identity. Get in touch with us at{" "}
            <a className={styles.link} href="mailto:team@foundation649.com">
              team@foundation649.com
            </a>
            !
          </div>
          <div className={styles.teamImgs}>
              {team.map((value, index) => {
                return <TeamMember key={index} member={value} />
              })}
            </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
